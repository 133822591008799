.user_home_body_accordion {
    .accordion-item{
        background-color: #DCDCDC;
        border-radius: 12px !important;
    
        .accordion-button{
            background-color: #DCDCDC;
            border-radius: 12px !important;
            width: auto;
    
            i {
                margin-left: 10px;
                color: teal;
            }
            
            &:hover {
                color: teal;
            }
    
            &:focus {
                outline: none;
                box-shadow: none;
                background-color: #DCDCDC;
                color: black;
            }
    
            &:not(.collapsed) {
                background-color: #DCDCDC;
                color: black;
                border: none;
                box-shadow: none;
            }
    
            &::after {
                margin-left: 10px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231E8080'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            }
        }
    }

    .hoverable {
        display: inline;
        &:hover .dropdown-menu {
            display: block !important;
        }
    }
    
    .dropdown-toggle {
        background-color: teal;
        border-radius: 13px;
        color: white;
        font-size: 13px;
        height: 27px;
        padding-top: 3px;
    }

    .badge-total-notifications{
        margin-left: 25px;
        font-size: 11px;
    }
}
