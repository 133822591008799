.coach-notes-container{
  .accordion-button {
    background-color: #FDF68C;

    &:not(.collapsed){
      color: inherit;
    }
  }

  .border-right{
    border-right: 1px solid #ccc!important;
  }
}


.accordion-button::after{
  margin-left: 10px;
}


