.custom-modal-width-sm {
  width: 570px;
}

.custom-modal-width-xl {
  width: 1070px;
  max-width: 1070px;
}

.teal-on-hover {
  &:hover, &:focus {
    color: teal;
  }
}
