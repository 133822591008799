.org-team-component{
    border-radius: 15px;

    .card-body{
        padding: 5px 10px;
    }

    .team-redirect-button{
        color: black;
        border: none;
        border-radius: 50px;
        margin: 0;
        padding: 1px 10px;

        &:hover{
            color: white;
        }
    }

    .notify-badge-item{
        margin: -4px 4px 0;
        padding: 0;
        cursor: pointer;
        float: left;
        width: auto;
        border: none;
        display: block;
        outline: 0;
    }

    .notify-badge{
		position: absolute;
		background:red;
		text-align: center;
		border-radius: 100%;
		color:white;
		padding:1px 5px;
		font-size:10px;
		font-weight: bold;
        margin-left: 23px;
        margin-top: -3px;
        //display: none;
    }

    .team-icons{
        width: 35px;
        cursor: pointer;
    }

    .badge-total-notifications{
        margin-left: 7px;
    }
    
    .collapsing {
        height: 0;
        overflow: hidden;
        //transition: height 3.07s ease;
      
        &.collapse-horizontal {
          width: 0;
          height: auto;
        }
      }
}

.org-team-z-mail-collapse-container{
    background-color: #E1FFCD;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}