body {
  background-color: rgb(252, 252, 252);
}

.div-rounded {
  display: inline-block;
  width: 80px;
  font-weight: bold;
  font-size: 18px;
  border: thin solid gray;
  border-radius: 10px;
  text-align: center;
  padding: 3px;
}
