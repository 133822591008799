#f1_container {
    position: relative;
    margin: 0 auto;
    width: 360px;
    z-index: 1;
    box-shadow: 0 3px 20px rgb(153 204 0);
    padding: 5px;

    #f1_card {
        width: 350px;
        height: 525px;
        transform-style: preserve-3d;
        transition: all 1.0s linear;

        .face {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            background: url(https://apps.zynity.com/images/books/HowYouLead-front.png) no-repeat;
            background-size: cover;
        }

        .face.back {
            display: block;
            transform: rotateY(180deg);
            background: url(https://apps.zynity.com/images/books/HowYouLead-back.png) no-repeat;
            background-size: cover;
        }
    }
    &:hover #f1_card{
        transform: rotateY(180deg);
    }
}

.tooltip {
    position: relative;
    opacity: 1 !important;
    z-index: 0 !important;
    line-height: inherit;
    font-weight: bold;
    font-size: 14px;
}
.tooltip .tooltiptext {
    line-height: 20px;
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    margin-left: 0px;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    font-size: 14px;
}
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip_a {
    position: relative;
    opacity: 1 !important;
    z-index: 0 !important;
    line-height: inherit;
    font-weight: bold;
    font-size: 14px;
}
.tooltip_a .tooltiptext_a {
    line-height: 20px;
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    /* bottom: 100%; */
    margin-left: 0px;
    top: 139px;
    left: 55%;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    font-size: 14px;
}
.tooltip_a .tooltiptext_a::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.tooltip_a:hover .tooltiptext_a {
    visibility: visible;
    opacity: 1;
}


.tooltip_b {
    position: relative;
    opacity: 1 !important;
    z-index: 0 !important;
    line-height: inherit;
    font-weight: bold;
    font-size: 14px;
}
.tooltip_b .tooltiptext_b {
    line-height: 20px;
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    /* bottom: 100%; */
    margin-left: 0px;
    top: 100px;
    left: 55%;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    font-size: 14px;
}
.tooltip_b .tooltiptext_b::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.tooltip_b:hover .tooltiptext_b {
    visibility: visible;
    opacity: 1;
}

.lead_pop_up {
    padding: 75px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 30px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_we {
    padding-top: 152px;
    padding-left: 112px;
    padding-right: 112px;
    padding-bottom: 152px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 30px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_serve {
    padding-top: 21px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 21px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 25px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_organization {
    padding-top: 40px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 25px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_things {
    padding-top: 20px;
    padding-left: 95px;
    padding-right: 95px;
    padding-bottom: 20px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 25px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_times {
    padding-top: 34px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 34px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 25px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_parts {
    padding: 62px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 30px;
    border-radius: 7px;
    text-align: center;
}
.lead_pop_up_resources {
    padding-top: 140px;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 140px;
    box-shadow: 0 3px 20px rgb(109, 162, 191);
    margin-top: 30px;
    border-radius: 7px;
    text-align: center;
}
.radialGrad {
    background-color: white;
    background-image: radial-gradient(circle, white, #EEE8AA);
}
