.usernews {
	border-radius: 6px;
	/* border: 5px solid #ddd; */
	box-shadow: 0 0 8px rgba(0,0,0,.5);
	float: left;
	border-radius: 10px;
	padding: 13px;
	margin-bottom: 25px;
}

.org-org-header {
    margin-left:0px; 
    width:100%; 
    background-color:teal; 
    color:white; 
    border-radius:12px; 
    margin-bottom:4px;

    h5{
        font-family: "Segoe UI",Arial,sans-serif;
        font-weight: 400;
        margin: 10px 0;
    }

    .add-org-btn {
        background:lightgray; 
        font-size:12px; 
        font-weight:bold; 
        color:black; 
        padding:8px; 
        height:30px; 
        border-radius:10px; 
        border:thin solid white;
        display: inline-block;
        text-decoration: none;
        margin-left: 15px;
    }
}