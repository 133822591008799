.imageUploaderComponentContainer {
  position: relative;

  .edit-btn {
    position: absolute;
    top: -5px;
    right: -3px;
    color: #494949;
    font-size: 27px;
    cursor: pointer;
    background-color: #efefef;
    padding: 5px;
    border-radius: 50%;

    &:hover {
      color: #757575;
    }
  }
}
