.nav {
    margin:0; padding:0; box-shadow:0 1px 2px #d8d8d8;
}
.comunityBtn {
    border:thin solid #ccc; 
    border-radius: 5px; 
	background:linear-gradient(90deg,  #d9e7ff 0%, #e3ffe7 100%);
    color: black;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    font-size:11px;
    padding:4px;
}

.linkComunity {
    text-decoration: none;
}

.remove-arrow.dropdown-toggle::after {
    border: none;
    content: none;
}

.hoverable:hover .dropdown-toggle {
    color: black;
    background-color: none;
}

.dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
}

.hoverable:hover .dropdown-menu {
    display: block !important;
}

.color-teal{
    color: teal;
}

.bg-teal{
    background-color: teal;
    color: white;
}

.zynity-slogan-item {
    padding: 5px 15px;
    margin-top: 7px !important;
    border: thin solid teal;
}

.zynity-slogan-button {
    font-size: 12px;
}

.zy-a {
    text-decoration: none;
    color: #9c0;
}

.spinner{
    animation: spin infinite 2s linear;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.btn-teal{
    color: #fff;
    background-color: teal;
    border-color: #019b9b;
}

.btn-teal:hover {
    color: #fff;
    background-color: #006c6c;
    border-color: #008a8a;
}

.clickable{
    cursor: pointer;
}
