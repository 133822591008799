.modal-dialog {
  max-width: 680px;
}

.ql-container {
  max-height: 200px; /* Ensure height doesn't grow beyond this */
  overflow-y: auto; /* Enable vertical scroll */
}

/* Customize scrollbar */
.ql-container::-webkit-scrollbar {
  width: 8px;
}

.ql-container::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
  border-radius: 4px;
}

.ql-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

.modalWithoutCloseMargin > .btn-close {
  margin: 0 !important;
}
