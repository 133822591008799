.org-team-z-mail-collapse-item{
    background-color: #F0FFF0;
    width: auto;
    display: inline;
    padding: 7px;
    font-size: 15px;

    .alias_circle {
        background-color: teal;
        display: inline;
        font-size: 12px;
        padding: 5px 7px;
        color: white;
        margin-right: 10px;

        &:hover + .z-mail-from-name {
            display: block;
        }
    }

    .z-mail-from-name{
        font-weight: bold;
        margin-right: 10px;
        display: none;
    }

    .z-mail-date{
        margin-left: 10px;
        font-size: 10px;
        margin-top: 3px;
    }

    .z-mail-trash-icon{
        margin-left: 5px;
        cursor: pointer;
        background: none;
        border: none;
        color: black;

        &:disabled{
            color: gray;
            cursor: progress;
        }

        &:hover{
            color: gray;
        }
    }
}