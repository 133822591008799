.user-main-tittle {
	float: left;
	padding-right: 80px;
}
.user-main-tittle h4 {
	margin-top: 5px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	padding-top: 10px;
}

.submenu-title {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
}

.btn-style-one {
	padding: 12px 25px;
	line-height: 12px;
	font-size: 14px;
	margin-top: 10px;
}
.button {
	padding:2px; 
	border-bottom: 2px solid #888; 
	border-right: 2px solid #888; 
	border-radius:4px; 
	background-color:#B0B0B0; 
	color:white;
	font-size:13px; 
	font-weight:normal;
}

.buttonHover {
	width:240px;
	border:none;
	text-align:left;
	background-color: white;
	color:black;
	transition-duration: 0.4s;
}

.buttonHover:hover {
	background-color: #dcdcdc; /* Green */
	color: black;
}

th.org_view a {
	vertical-align: inherit !important;
}

.userHomeMessage{
    display:inline-block; 
    font-size:12px; 
    border:thin solid red; 
    padding:4px; 
    margin:4px;
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

/*Btn Style One*/

.btn-style-one{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:16px;
	font-weight:600;
	border-radius:50px;
	background:#99cc00;
	border:2px solid #99cc00;
	font-family: 'Poppins', sans-serif;
	height:28px; 
	width:140px; 
	text-align:center; 
	padding-top:0px;
}

.btn-style-one:hover{
	color:#99cc00;
	background:none;
	border-color:#99cc00;
}

.data-table-container > div:first-of-type {
	overflow: visible;
}
.bg-note {
	background-color: #FFFADE;
}
